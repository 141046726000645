import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { Container, Row, Col, Form } from "react-bootstrap"
import { navigate, useMatch } from "@reach/router"
import ScrollAnimation from 'react-animate-on-scroll';
// import Img from 'gatsby-image';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from "html-react-parser";
import PlayVideo from '../../../components/Play/PlayVideo';
import AdvisorImg from "../../../images/area-guide/advisors.jpg";
import { FIND_A_PROPERTY_PAGE_URL, VALUATION_PAGE_URL, BRANCHES_PAGE_URL } from "../../common/site/constants"
import SearchResultInputBox from "../../predictive-search/SearchResultBoxStaticPage"
import { removeSpecialChar, hyphenToCaptilize, QueryList } from "../../utils/property-search"

import "./VideoBlock.scss"
// markup
const VideoBlock = (props) => {
  const [isPlay, setPlay] = useState(false);
  const [areaVal, setAreaVal] = useState('')
  const queryList = QueryList();

  const shareurl = typeof window !== 'undefined' ? window.location.href : ''


  function search(val) {
    let url = "/property/for-sale/";

    var searcValue = val.split(', ').join('-').replace(/ /g, "-").replace(/'/g, "").toLowerCase();
    if (searcValue == "") {
      localStorage.removeItem('searchValue');
    }

    if (searcValue !== "") {
      url += "in-" + searcValue + "/"
    } else {
      localStorage.setItem('searchValue', 'billingshurst-west-sussex');
      url += "in-billingshurst-west-sussex/radius-30-miles/"
    }
    navigate(url)
    // window.location.href = url;
    // if (page == "property") {
    // window.location.reload();
    // }
  }

  return (
    <React.Fragment>
      <div className="video-block-wrap components--AreaGuideDetails--VideoBlock--VideoBlock">
        <Container>
          <Row>
            <Col md="12">
              {props.Image &&
                <div className="guide-detail-banner-img">
                  <img src={props.Image.url} alt={props.Image.alternativeText} />
                </div>
                // <Img fluid={props.Image.url_sharp.childImageSharp.fluid} alt={props.Image.alternativeText} />
                // <GatsbyImage image={getImage(props.Image.url_sharp)} alt={props.Image.alternativeText} />
              }
            </Col>
            <Col md="12" lg="8">
              {props.Content &&
                <div className="block-content">
                  <div className="block-text">
                    {parse(props.Content)}
                  </div>
                </div>
              }
            </Col>
            <Col md="8" lg="4" className="block-content block-text">
              <div className="guide-details-right-block main-banner">
                <div className="first">
                  <form>
                    <div class="form-group">
                      <SearchResultInputBox
                        value={areaVal !== 'billingshurst-west-sussex' ? hyphenToCaptilize(areaVal) : ''}
                        type={props.Search_Type}
                        setValue={(searchValue, isSelected) => {
                          // refine(removeSpecialChar(searchValue)) 
                          if (isSelected) {
                            localStorage.setItem('searchValue', removeSpecialChar(searchValue));
                          } else {
                            localStorage.removeItem('searchValue');
                          }
                          setAreaVal(searchValue)
                        }}
                        placeHolder={`Enter town or area`}
                        areaList={queryList.areaList}
                      />
                      {/* <i class="icon-pin"></i>
                      <input placeholder="Enter your postcode or area" type="text" class="address form-control" /> */}
                    </div>
                    <div className="btn-wrap row">
                      <Col lg={6} className="cta col-1">
                        <Link to={VALUATION_PAGE_URL.alias} className="btn btn-secondary">
                          <span>Selling or letting?</span>Book a valuation
                        </Link>
                      </Col>
                      <Col lg={6} className="cta col-2">
                        <a href="javascript:void(0)" onClick={() => { search(areaVal) }} className="btn">
                          <span>Buying or renting?</span>Find a property
                        </a>
                      </Col>
                    </div>
                  </form>

                  {props.People &&
                    <div className="advisor-info">
                      <div className="advisor-img">
                        <img src={props.People.Image.url} alt={props.People.Image.alternativeText} />
                      </div>
                      <div className="advisor-contact">
                        <span className="advisor-name">{props.People.Name}</span>
                        <span className="advisor-designation">{props.People.Designation}</span>
                        <a href={`tel:${props.People.Phone}`} className="phone-number">
                          <i className="icon-phone"></i>
                          <span>{props.People.Phone}</span>
                        </a>
                      </div>
                    </div>
                  }
                </div>

                <div className="info-text">
                  <p>With over 40 branches across Cubitt & West, we’re sure to have a team of friendly agents local to you. <Link to={BRANCHES_PAGE_URL.alias}>Find your local team today.</Link></p>
                </div>


              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default VideoBlock
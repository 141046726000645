import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import ScrollAnimation from "react-animate-on-scroll";
import { Row, Col, Container, Button } from "react-bootstrap";
// import Img from 'gatsby-image';
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from 'html-react-parser';
import GenerateLink from "../common/site/generate-link"
import NoImage from "../../images/cubitt-no-image.png"
import _ from "lodash"

const OtherGuides = (props) => {
    return (
        <div className="other-guides">
            <Container>
                <Row>
                    <h3>May also interest you...</h3>
                    {props.guides && props.guides.map((item, index) => (
                        <Col lg={4} md={6}>
                            <div className="item">
                                <Link to={`../${item.URL}`}>
                                    <div className="img-zoom">
                                        {item.Tile_Image ?
                                            <img src={item.Tile_Image.url} alt={item.Tile_Image.alternativeText} />
                                            // <GatsbyImage image={getImage(item.Tile_Image.url_sharp)} alt={item.Tile_Image.alternativeText} />
                                            // <Img fluid={item.Tile_Image.url_sharp.childImageSharp.fluid} alt={item.Tile_Image.alternativeText} />
                                            : <img src={NoImage} alt={item.Title} />
                                        }
                                    </div>
                                    <p><span>{item.Title}</span></p>
                                </Link>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    )
}

export default OtherGuides;